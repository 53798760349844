import { FC, useCallback, useMemo, useRef, useState } from "react"

type ExtendedProps<Result = unknown, Data = unknown> = {
   resolve: (val?: Result) => void; onCancel: () => void; initialData: Data; open: boolean }

export type AwaitableModal<Result = unknown, Data = unknown> = FC<ExtendedProps<Result, Data>>

const useAwaitableModal: <Result = unknown, Data = unknown>(
  Modal: AwaitableModal<Result, Data>,
  initialData: Data) => [(data?: Data) => Promise<Result>, () => JSX.Element] = (Modal, initialData) => {
    const [requesting, setRequesting] = useState(false)
    const resolve = useRef<((val: any) => void) | undefined>()
    const reject = useRef<(() => void) | undefined>()
    const [data, setData] = useState(initialData)

    const request = useCallback(async(data?: typeof initialData) => {
      if (requesting) return
      if (data) setData(data)
      const newPromise = new Promise<any>((_resolve, _reject) => {
        resolve.current = _resolve
        reject.current = _reject
      }).finally(() => {
        setRequesting(false)
        resolve.current = undefined
        resolve.current = undefined
      })
      setRequesting(true)

      return newPromise
    }, [requesting])

    const ModalC = useMemo(() => {
      return () => (
        <Modal
          open={requesting}
          resolve={(val?: any) => resolve.current?.(val as any)}
          onCancel={() => reject.current?.()}
          initialData={data}
        />
      )
    }, [requesting, resolve, reject, data, Modal])

    return [request, ModalC]
  }

export default useAwaitableModal
