import { LogoSrc } from "assets"
import { supportedChainsConfig } from "constants/chains"
import { extensionId } from "constants/extension"
import { useAuth } from "contexts/Auth"
import { FC } from "react"
import { Outlet } from "react-router"

const shortenAddress = (address: string) => {
  return `${address.slice(0, 6)}...${address.slice(-4)}`
}

const MainLayout: FC = () => {
  const { user, signInWithEthereum, authInProgress, signInWithPhantom } = useAuth()
  const chain = user?.uid.split(":")[1]
  const chainData = chain ? supportedChainsConfig.find(c => c.id === chain) : null

  const connectText = authInProgress ? "Connecting..." : user ? `${shortenAddress(user.uid.split(":")[2])}` : "Connect"

  return (
    <div className="w-full h-full flex bg-bg-primary overflow-hidden">
      <div className="grow flex flex-col h-full overflow-hidden">
        <div className="w-full h-24 shrink-0 bg-bg-primary border-b border-b-border-primary flex items-center justify-between px-16">
          <div className="flex items-center gap-4">
            <img src={LogoSrc} className="h-14 w-14" />
            <h1 className="text-lg font-bold text-white">
              TrenchScout
            </h1>
          </div>
          <div className="flex items-center gap-4">
            <button
              onClick={() => {
                window.open(`https://chromewebstore.google.com/detail/${extensionId}`, "_blank")
              }}
              className="bg-white flex items-center
              hover:bg-[rgba(255,255,255,0.9)]
             text-black border border-white transition-colors text-base px-5 gap-2 py-3 rounded-lg"
            >
              Get Extension
            </button>
            <button
              onClick={signInWithPhantom}
              className="bg-bg-primary flex items-center
              hover:bg-[rgba(255,255,255,0.1)]
             text-white border border-border-primary transition-colors text-base px-5 gap-2 py-3 rounded-lg"
            >
              {/*<div dangerouslySetInnerHTML={{ __html: chainData?.svg || "" }} className="[&>svg]:w-8 [&>svg]:h-8" />*/}
              {connectText}
            </button>
          </div>
        </div>
        <div className="bg-black grow w-full relative">
          <div className="grow h-full w-full absolute z-[1]">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainLayout
