export const redirectAndComeBack = (to: string, trigger: string) => {
  const current = window.location.pathname
  const currentRedirects = JSON.parse(localStorage.getItem("redirects") || "{}") as Record<string, string>
  currentRedirects[trigger] = current
  localStorage.setItem("redirects", JSON.stringify(currentRedirects))

  window.location.pathname = to
}

export const comeBack = (trigger: string) => {
  const currentRedirects = JSON.parse(localStorage.getItem("redirects") || "{}") as Record<string, string>
  const url = currentRedirects[trigger]

  delete currentRedirects[trigger]

  localStorage.setItem("redirects", JSON.stringify(currentRedirects))

  if (!url) return null

  window.location.pathname = url
}
