import { uuidV4 } from "ethers"
import { FC } from "react"

const Progress = () => {
  const id = uuidV4(crypto.getRandomValues(new Uint8Array(16)))
  const percentage = 1
  const length = Math.PI * 170
  const offset = percentage * length

  return (
    <svg width="170" height="170" viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <mask id={id}>
          <rect x={0} y={0} width={170} height={170} fill="black" />
          <circle
            cx={85}
            cy={85}
            r={83}
            strokeWidth={2}
            stroke="white"
            fill="black"
            strokeLinecap="round"
            strokeDashoffset={0}
            strokeDasharray={[offset].join(" ")}
          />
        </mask>
      </defs>
      <foreignObject mask={`url(#${id})`} x={0} y={0} className="overflow-visible" width={170} height={170}>
        <div
          style={{
            background: "conic-gradient(from 180deg at 50% 50%, #000000 0deg, #000000 129.6deg, #8E8E8E 360deg)",
            width: 170,
            height: 170,
          }}
        />
      </foreignObject>
    </svg>
  )
}

const Loader: FC = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="animate-spin">
        <Progress />
      </div>
    </div>
  )
}

export default Loader
