import { FC, useEffect } from "react"
import { createPortal } from "react-dom"

interface props {
  open: boolean
  close: (...args: any) => void
}

const modalCounterKey = "data-modals-mounted"

const Modal: FC<React.PropsWithChildren<props>> = ({ children, open, close }) => {
  useEffect(() => {
    if (!open) return
    const currentModalCount = document.body.getAttribute(modalCounterKey) || "0"
    document.body.setAttribute(modalCounterKey, (Number(currentModalCount) + 1).toString())

    return () => {
      const currentModalCount = document.body.getAttribute(modalCounterKey) || "0"
      const nextCount = Number(currentModalCount) - 1
      if (nextCount>0) {
        document.body.setAttribute(modalCounterKey, nextCount.toString())
      } else {
        document.body.removeAttribute(modalCounterKey)
      }
    }
  }, [open])

  return createPortal(
    <div
      className="fixed inset-0 w-full h-full overflow-hidden p-16 bg-[rgba(0,0,0,0.5)] backdrop-blur-sm
     p-8 flex items-center justify-center z-[100000000] transition-all duration-100"
      style={{
        opacity: open ? 1 : 0,
        pointerEvents: open ? "auto" : "none",
      }}
      onClick={() => close()}
    >
      <div
        className="w-max max-h-max max-w-full h-full overflow-hidden flex items-center
      "
        onClick={e => e.stopPropagation()}
      >{children}
      </div>
    </div>,
    document.body,
  )
}

export default Modal
