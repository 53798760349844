import { Connection, PublicKey, SystemProgram, Transaction } from "@solana/web3.js"
import userApi from "api/user"
import { isAxiosError } from "axios"
import Loader from "components/Loader"
import toasts from "components/toasts"
import { paymentAddress, paymentAmount } from "constants/payments"
import { useAuth } from "contexts/Auth"
import { useUser } from "contexts/User"
import { FC, useState } from "react"

const getPaymentTx = (address: string) => {
  const tx = new Transaction()
  const senderPK = new PublicKey(address)
  tx.add(SystemProgram.transfer({
    fromPubkey: senderPK,
    toPubkey: new PublicKey(paymentAddress),
    lamports: paymentAmount,
  }))
  tx.feePayer = senderPK

  return tx
}

const MainPage: FC = () => {
  const { user: authUser } = useAuth()
  const { user, setUser } = useUser()
  // eslint-disable-next-line
  const [sentTxId, setSentTxId] = useState<string>("")
  const [txId, setTxId] = useState<string>("")
  const [verifying, setVerifying] = useState<boolean>(false)

  const onPay = async() => {
    if (!user) return
    const provider = window.phantom.solana
    await provider.connect()
    const connection = new Connection("https://solana-rpc.publicnode.com", "confirmed")
    const tx = getPaymentTx(user.address)
    const bk = await connection.getLatestBlockhash()
    tx.recentBlockhash = bk.blockhash
    // @ts-ignore
    await provider.signAndSendTransaction(tx, connection).then(res => {
      if (!res?.signature) return
      const signature = res.signature
      setTxId(signature)
      setSentTxId(signature)
      toasts.success("Transaction sent successfully")
    })
  }

  const onVerify = async() => {
    if (verifying) return
    if (!txId) return toasts.error("Please enter a valid transaction id")
    setVerifying(true)
    userApi.verifyPayment(txId).then(user => {
      setUser(user)
      toasts.success("Payment verified successfully")
    }).catch((err: unknown) => {
      if (isAxiosError(err)) {
        if (err.response?.status === 400) {
          return toasts.error(err.response.data || "Payment verification failed")
        }
      }
      toasts.error("Payment verification failed")
    }).finally(() => {
      setVerifying(false)
    })
  }

  if (authUser === null) {
    return (
      <div className="w-full h-full py-10 px-16">
        <div
          className="flex flex-col items-center justify-center h-full gap-6 grow bg-bg-secondary rounded-2.5 border border-border-primary"
        >
          <h1 className="text-4xl font-semibold text-white">Restricted Area</h1>
          <span className="text-text-secondary text-base">
            You need to connect your wallet to continue
          </span>
        </div>
      </div>
    )
  }

  if (!user || verifying) return <Loader />

  if (!user?.paymentTx && false) {
    return (
      <div className="w-full h-full py-10 px-16 overflow-y-auto">
        <div
          className="flex flex-col items-center justify-center
           h-max gap-6 grow bg-bg-secondary rounded-2.5 border border-border-primary pt-20 pb-40"
        >
          {!sentTxId ? (
            <>
              <h1 className="text-4xl font-semibold text-white">Payment Required</h1>
              <span className="text-text-secondary text-base">
                A one-time payment is required to access the features
              </span>
              <input
                type="text"
                className="bg-slate-950 text-white hover:bg-slate-900 w-[36rem] text-center transition-colors text-base
              px-8 border border-transparent hover:border-white py-5 rounded-full"
                readOnly
                value={paymentAddress}
                onClick={() => {
                  navigator.clipboard.writeText(paymentAddress)
                  toasts.success("Copied to clipboard")
                }}
              />
              <span className="text-sm text-text-secondary text-center -my-2">
                Send <span className="text-white">0.25 SOL</span> to address above
              </span>
              {/*<button
                onClick={onPay}
                disabled={false}
                className="bg-white text-black hover:bg-slate-100 transition-colors text-base
              px-8 border border-transparent hover:border-black py-5 rounded-full"
              >
                Pay Using Phantom
              </button>*/}
            </>
          ) : (
            <>
              <h1 className="text-4xl font-semibold text-white">Payment Sent</h1>
              <span className="text-white text-base">
                View the transaction status on Solana Explorer
              </span>
              <input
                type="text"
                className="bg-slate-950 text-white hover:bg-slate-900 w-[36rem] text-center transition-colors text-base
              px-8 border border-transparent hover:border-white py-5 rounded-full"
                readOnly
                value={sentTxId}
                onClick={() => {
                  window.open(`https://solscan.io/tx/${sentTxId}`, "_blank")
                }}
              />
            </>
          )}
          <h2 className="text-white text-base">
            Once the transaction is finalized on chain, verify the payment
          </h2>
          <input
            type="text"
            className="bg-slate-950 text-white hover:bg-slate-900 transition-colors text-base
              px-8 border border-transparent text-center hover:border-white py-5 rounded-full"
            placeholder="Enter Payment Id"
            value={txId}
            onChange={e => setTxId(e.target.value)}
          />
          <button
            onClick={onVerify}
            disabled={verifying}
            className="bg-white text-black hover:bg-slate-100 transition-colors text-base
              px-8 border border-transparent hover:border-black py-5 rounded-full"
          >
            Verify Payment
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="w-full h-full py-10 px-16 overflow-y-auto">
      <div
        className="flex flex-col items-center justify-center min-h-full
           h-max gap-6 grow bg-bg-secondary rounded-2.5 border border-border-primary pt-20 pb-40"
      >
        <h1 className="text-4xl font-semibold text-white">Dashboard</h1>
        <span className="text-sm text-text-secondary text-center -my-2">
          Welcome back, <span className="text-white">{user.address}</span>!
        </span>
        {/*<span className="text-sm text-text-secondary text-center -my-2">
          Your payment: <span className="text-white">{user.paymentTx}</span>
        </span>*/}
        <button
          onClick={() => window.open("https://pump.fun", "_blank")}
          className="bg-white text-black hover:bg-slate-100 transition-colors text-base
              px-8 border border-transparent hover:border-black py-5 rounded-full mt-2"
        >
          Open pump.fun
        </button>
      </div>
    </div>
  )
}

export default MainPage
