import Modal from "components/Modal"
import firebase from "config/firebase"
import { supportedChainsConfig } from "constants/chains"
import { useAuth } from "contexts/Auth"
import { signOut } from "firebase/auth"
import { AwaitableModal } from "hooks/useAwaitableModal"
import { useEffect, useState } from "react"

export interface ChainPickerInput {
    chain: string
    addresses: string[]
}

export interface ChainPickerOutput {
    chain: string
    address: string
}

const ChainPickerModal: AwaitableModal<ChainPickerOutput, ChainPickerInput> = ({ resolve, onCancel, initialData, open }) => {
  const { user } = useAuth()
  const [chain, setChain] = useState(initialData.chain)
  const [address, setAddress] = useState(initialData.addresses[0])

  const onDisconnect = () => {
    setChain("")
    setAddress("")
    user && signOut(firebase.auth)
  }

  const chainSelected = supportedChainsConfig.find(c => c.id === chain)

  useEffect(() => {
    if (chain && !chainSelected) {
      if (!chainSelected) {
        setChain("")
      } else {
        setAddress(initialData.addresses[0])
      }
    }
  }, [chain, chainSelected, initialData.addresses])

  return (
    <Modal open={open} close={onCancel}>
      <div className="bg-bg-primary rounded-lg shadow-lg h-max max-h-full flex flex-col">
        <h1 className="text-2xl font-bold p-4 text-white">Select a chain</h1>
        <div className="overflow-y-auto px-4 max-h-max">
          {!chainSelected ? (
            <div className="grid grid-cols-3 h-full gap-2">
              {
                supportedChainsConfig.map(({ name, svg, id }) => (
                  <button
                    key={id}
                    onClick={() => setChain(id)}
                    className={`flex flex-col items-center justify-center p-3 
                rounded-lg border-transparent border hover:border-border-primary transition-colors
                 ${id === chain ? "border-border-primary bg-black" : ""}`}
                  >
                    <div dangerouslySetInnerHTML={{ __html: svg }} className="w-8 h-8 rounded-full [&>svg]:w-8 [&>svg]:h-8" />
                    <span className="mt-2 text-xs text-white">{name}</span>
                  </button>
                ))
              }
            </div>
          ) : (
            <div className="flex items-center gap-4">
              <button
                onClick={() => setChain("")}
                className={`flex items-center justify-center p-2 gap-4
                rounded-lg border hover:border-transparent hover:opacity-90 transition-colors
                border-border-primary bg-black`}
              >
                <div dangerouslySetInnerHTML={{ __html: chainSelected.svg }} className="w-8 h-8 rounded-full [&>svg]:w-8 [&>svg]:h-8" />
              </button>
              <div className="flex flex-col">
                {
                  initialData.addresses.map((addr, i) => (
                    <button
                      key={i}
                      onClick={() => setAddress(addr)}
                      className={`flex items-center justify-between p-3 rounded-lg
                     border-transparent border hover:border-border-primary transition-colors
                 ${addr === address ? "border-border-primary bg-black" : ""}`}
                    >
                      <span className="text-white">{addr}</span>
                      <span className="text-white ml-2">{addr === address ? "✅" : ""}</span>
                    </button>
                  ))
                }
              </div>
            </div>
          )}
        </div>
        <div className="flex items-center justify-center p-4 gap-4">
          {
            chainSelected && address ? (
              <>
                <button
                  onClick={onDisconnect}
                  className="bg-black text-white border border-transparent transition-colors
                hover:border-border-primary px-4 py-2 rounded-lg mr-4"
                >
                  {user ? "Disconnect" : "Cancel"}
                </button>
                <button
                  onClick={() => resolve({ chain, address })}
                  className="text-white border
                    bg-gradient-to-t from-[#7739C5] to-[#974DDA]
                    border-transparent
                    hover:border-border-primary
                    hover:opacity-90
                    transition-all
                    px-4 py-2 rounded-lg mr-4"
                >
                  Connect
                </button>
              </>
            ) : (
              <button
                onClick={onCancel}
                className="bg-black text-white border border-transparent transition-colors
                hover:border-border-primary px-4 py-2 rounded-lg mr-4"
              >
                Cancel
              </button>
            )
          }
        </div>
      </div>
    </Modal>
  )
}

export default ChainPickerModal
