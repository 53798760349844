import { useAuth } from "contexts/Auth"
import { FC } from "react"
import { Navigate, Outlet, Route, Routes } from "react-router"
import MainLayout from "layouts/Main"
import AuthorizeExtensionPage from "pages/private/extension/authorize"
import MainPage from "pages/private"

const RootRouter: FC = () => {
  const auth = useAuth()

  if (auth.user === undefined) return <div>Loading ...</div>

  return (
    <>
      <div
        className="fixed inset-0 transition-all"
        style={
          auth.authInProgress
            ? {
              background: "rgba(0, 0, 0, 0.5)",
              pointerEvents: "auto",
            } : {
              background: "rgba(0, 0, 0, 0)",
              pointerEvents: "none",
            }
        }
      />
      <Routes>
        <Route element={<MainLayout />}>
          <Route index element={<MainPage />} />
          <Route path="extension" element={<Outlet />}>
            <Route path="authorize" element={<AuthorizeExtensionPage />} />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </>
  )
}

export default RootRouter
