import { FirebaseOptions, initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getAnalytics } from "firebase/analytics"

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyDraNMnVtk7AAuHy-t8L3dbsaJFUz4Rqw0",
  authDomain: "memeprophet-32ae6.firebaseapp.com",
  projectId: "memeprophet-32ae6",
  storageBucket: "memeprophet-32ae6.appspot.com",
  messagingSenderId: "417754038005",
  appId: "1:417754038005:web:23b8857be7aa285f714fbc",
  measurementId: "G-06E25VXTYG",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const analytics = getAnalytics(app)

const firebase = { app, auth, db, analytics }

export default firebase
