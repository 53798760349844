import AuthProvider from "contexts/Auth"
import UserProvider from "contexts/User"
import isMobile from "helpers/isMobile"
import { FC, useEffect } from "react"
import { BrowserRouter } from "react-router-dom"
import { Bounce, ToastContainer } from "react-toastify"
import RootRouter from "routes"
import "react-toastify/dist/ReactToastify.css"

const Root: FC = () => {
  useEffect(() => {
    if (!isMobile()) return
    const styleSheets = Array.from(document.styleSheets)
    styleSheets.forEach(styleSheet => {
      if (styleSheet.ownerNode?.nodeName === "STYLE") {
        const rules = Array.from(styleSheet.cssRules)
        rules.forEach(rule => {
          if (rule instanceof CSSStyleRule) {
            if (rule.selectorText.includes(":hover")) {
              rule.selectorText = rule.selectorText.replace(":hover", ":active")
            }
          }
        })
      }
    })
  }, [])

  return (
    <BrowserRouter>
      <AuthProvider>
        <UserProvider>
          <RootRouter />
        </UserProvider>
      </AuthProvider>
      <ToastContainer position="bottom-center" hideProgressBar stacked={false} limit={1} transition={Bounce} />,
    </BrowserRouter>
  )
}

export default Root
