import { AxiosInstance } from "axios"
import axios from "./axios"
import { IUser } from "types/user"

class UserApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  async getUser() {
    return this.axios.get("/user").then(res => res.data as IUser)
  }

  async verifyPayment(txId: string) {
    return this.axios.post("/user/payment", { txId }).then(res => res.data as IUser)
  }
}

const userApi = new UserApi()

export default userApi
